import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { path: '/', component: () => import('../views/Home.vue') },
  { path: '/login', component: () => import('../views/Login.vue') },
  { path: '/clientes', component: () => import('../views/Clientes/ConsultaClientes.vue') },
  { path: '/clientes/new', component: () => import('../views/Clientes/EditaClientes.vue') },
  { path: '/clientes/edit/:id', component: () => import('../views/Clientes/EditaClientes.vue') },
  { path: '/cidades', component: () => import('../views/Cidades/ConsultaCidades.vue') },
  { path: '/cidades/new', component: () => import('../views/Cidades/EditaCidades.vue') },
  { path: '/cidades/edit/:id', component: () => import('../views/Cidades/EditaCidades.vue') },
  { path: '/bairros/:id_cidade', component: () => import('../views/Bairros/ConsultaBairros.vue') },
  { path: '/bairros/new/:id_cidade', component: () => import('../views/Bairros/EditaBairros.vue') },
  { path: '/bairros/edit/:id_cidade/:id', component: () => import('../views/Bairros/EditaBairros.vue') },
  { path: '/usuarios', component: () => import('../views/Usuarios/ConsultaUsuarios.vue') },
  { path: '/usuarios/new', component: () => import('../views/Usuarios/EditaUsuarios.vue') },
  { path: '/usuarios/edit/:id', component: () => import('../views/Usuarios/EditaUsuarios.vue') },
  { path: '/imoveis', component: () => import('../views/Imoveis/ConsultaImoveis.vue') },
  { path: '/imoveis/new', component: () => import('../views/Imoveis/EditaImoveis.vue') },
  { path: '/imoveis/edit/:id', component: () => import('../views/Imoveis/EditaImoveis.vue') },
  { path: '/imoveis/capa/:id', component: () => import('../views/Imoveis/CapaImoveis.vue') },
]

const router = new VueRouter({
  routes
})

export default router
